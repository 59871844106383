.mobile_nav_modal .modal-content{
    background-color: rgb(215, 179, 125);
    color: rgb(77 30 0);
}

.mobile_nav_modal .icon_wrap{
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 30px;
}

.mobile_nav_modal .Navbar_Item{
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
}

.mobile_nav_modal .Navbar_Item span{
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 500;
}

.mobile_nav_modal .Navbar_Item div{
    font-size: 20px;
    display: flex;
    align-items: center;
}
.mobile_nav_modal .button_wrap{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
}
.mobile_nav_modal .connect_button{
    width: 172px;
    height: 60px;
    background-color: #4d83f7;
    /* background: url(/src/assets/svgs/blue-btn-background.svg); */
    background: url("/public/images/blue-button.png");
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 50% !important;
    font-size: 24px;
    font-weight: 600;
}