.home_header a{
    text-decoration: none;
    color: white;
}

.home_header a:hover{
    color: rgb(172, 107, 52);
}

.home_header .active{
    color: rgb(172, 107, 52);
}

.home_header ul{
    margin-bottom: 0px;
}

.about{
    background-color: rgb(5, 4, 2) !important;
}

.about .description{
    color: rgb(135, 142, 153);
    font-size: 16px;
    font-family: Work Sans;
    line-height: 1.5rem;
}

.about .join{
    text-decoration: none;
}

.about .join:hover{
    color: rgb(172 107 52);
}

.portfolio{
    background-color: rgb(28 29 36);
}

.portfolio .subtitle{
    max-width: 520px;
    font-size: 1rem;
    line-height: 1.5rem;
    --tw-text-opacity: 1;
    color: rgb(135 142 153);
    font-family: Work Sans;
}

.portfolio .created_by{
    color: rgb(135 142 153);
}

.portfolio .view{
    text-decoration: none;
}

.portfolio .view:hover{
    color: #AC6B34;
}
.pondmap h2{
    text-align: start;
}

.pondmap p{
    text-align: start;
}
.pondmap .description{
    font-family: Work Sans;
    line-height: 1.5rem;
    --tw-text-opacity: 1;
    color: rgb(135 142 153);
}

.services{
    background-color: rgb(5 4 2);
}

.services .description{
    font-family: Work Sans;
    line-height: 1.5rem;
    --tw-text-opacity: 1;
    color: rgb(135 142 153);
}

.services .member{
    background-color: rgb(28 29 36);
}
.hero{
    background-color: rgb(5, 4, 2) ;
}